import { Button } from 'grommet'
import React from 'react'
import { colors } from '../../../theme'
import { BlockButton, Content, Wrapper } from './index.styles'

const AffiliatePage = (props) => {
  return <Wrapper>
    <Content>
    <h1>Night Zookeeper Affiliate Program </h1>
<p></p>Become a Night Zookeeper Affiliate today! 

<p>Are you an influencer, or leader of an online parenting group with a blog, Youtube, or large social media following? Do you think you can reach out to large communities of parents and homeschooling families who might be interested in using Night Zookeeper for their children? Become a Night Zookeeper Affiliate!</p>
<p>It’s simple! <b>Earn money</b> for bringing paid sign-ups to the platform while promoting creativity and education. Applying to be an affiliate is free! </p>
<h2>How to become a Night Zookeeper affiliate: </h2>
<h2>How do I request to join?</h2>

<p>
  <b>Please fill out</b> <a href="https://forms.clickup.com/f/22daj-1303/2GQUL88Z12LQE5Y9I9" target="_blank" rel="noopener noreferrer">this form</a>, letting us know where you plan to share the word about Night Zookeeper! 
</p>
<BlockButton>
  <a href="https://forms.clickup.com/f/22daj-1303/2GQUL88Z12LQE5Y9I9" target="_blank" rel="noopener noreferrer">
    <Button primary color={colors.primary}>Form</Button>
  </a>
</BlockButton>

<h2>
  How does it work?
</h2>

<p>
If your application is accepted, we will be in touch with the next steps (including your contract, personalised affiliate link and approved assets to use for promoting!). 
</p>

<h2>
Benefits of being a Night Zookeeper Affiliate:
</h2>
<ul>
<li>Promote education, inspire and engage children!</li>
<li>Earn $5 per customer who starts a paid subscription.</li>
<li>14-day cookie duration for your affiliate link.</li>
<li>Receive a personalized 50% off discount link to promote.</li>
<li>Gain access to creative resources such as approved NZK badges, images and email banners. You’ll also be assigned a personal Affiliate Manager to help you through the process. </li>
</ul>


<BlockButton>
  <a href="https://forms.clickup.com/f/22daj-1303/2GQUL88Z12LQE5Y9I9" target="_blank" rel="noopener noreferrer">
    <Button primary color={colors.primary}>Sign up now!</Button>
  </a>
</BlockButton>
</Content>

  </Wrapper>
}

AffiliatePage.propTypes = {

}

AffiliatePage.defaultProps = {

}

export default AffiliatePage
