import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #fefefe;
  padding: 20px;
`

export const Content = styled.div`
  background-color: #fff;
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 1px 1px 6px rgba(0,0,0,0.3);
  font-family: "Libre Baskerville";
  h1 {
    font-family: "Rammetto One", cursive;
  }
`

export const BlockButton = styled.div`
  display: flex;
  justify-content: center;
`
